<template>
    <div class="database">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>查询</span>

                    <el-input
                        size="small"
                        style="width: 150px"
                        placeholder="请输入标题"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getData()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>创建时间</span>
                    <el-radio-group v-model="dateChoice" size="small">
                        <el-radio-button
                            label="3天内"
                            @click.native.prevent="clickitem('3天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="10天内"
                            @click.native.prevent="clickitem('10天内')"
                        ></el-radio-button>
                        <el-radio-button
                            label="30天内"
                            @click.native.prevent="clickitem('30天内')"
                        ></el-radio-button>
                    </el-radio-group>
                    <el-date-picker
                        v-model="searchVal.searchDate"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        clearable
                        style="margin-left: 10px"
                        @change="getData()"
                    >
                    </el-date-picker>
                </p>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="getData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        @click="searchEmpty()"
                        type="primary"
                        style="
                            margin: 0 16px 0 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        >重置</el-button
                    >
                    <el-button
                        v-show="fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right: 29px; color: #2162cb"
                    >
                        收起 <i class="el-icon-arrow-up"></i>
                    </el-button>
                    <el-button
                        v-show="!fold"
                        @click="fold = !fold"
                        type="text"
                        style="margin-right: 29px; color: #2162cb"
                    >
                        展开 <i class="el-icon-arrow-down"></i>
                    </el-button>
                </p>
            </div>
            <div class="search-two" v-if="fold">
                <span>状态</span>
                <el-radio-group v-model="status" size="small">
                    <el-radio-button
                        label="待处理"
                        @click.native.prevent="clickStatus('待处理')"
                    ></el-radio-button>

                    <el-radio-button
                        label="处理中"
                        @click.native.prevent="clickStatus('处理中')"
                    ></el-radio-button>

                    <el-radio-button
                        label="已处理"
                        @click.native.prevent="clickStatus('已处理')"
                    ></el-radio-button>
                    <el-radio-button
                        label="已关闭"
                        @click.native.prevent="clickStatus('已关闭')"
                    ></el-radio-button>
                </el-radio-group>
                <Department
                    ref="department"
                    :departmentWidth="170"
                    :adminWidth="170"
                    :depSearch="btnP.depSearch"
                    :searchUser="btnP.searchUser"
                    @searchData="searchData"
                ></Department>
                <!-- <span v-show="btnP.depSearch">部门</span>
                <el-select
                    v-model="searchVal.departmentId"
                    v-show="btnP.depSearch"
                    size="small"
                    style="width: 170px"
                    clearable
                    placeholder="请选择"
                    @change="
                        (currentPage = 1), (pagesize = 50), changeDepartmentId()
                    "
                >
                    <el-option
                        v-for="(item, index) in departmentList"
                        v-if="index > 0"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span v-show="btnP.searchUser">成员</span>
                <el-select
                    v-show="btnP.searchUser"
                    style="margin-left: 10px; width: 170px"
                    v-model="searchVal.adminId"
                    size="small"
                    clearable
                    placeholder="请选择"
                    @change="getData()"
                >
                    <el-option
                        v-for="item in userName"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select> -->
            </div>
        </div>
        <div class="table-title">
            <p>工单列表</p>
            <span>
                <button @click="onAdd()" v-if="btnP.add">
                    <i class="el-icon-plus"></i> 新建工单
                </button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column
                label="工单号"
                prop="workOrderNo"
                show-overflow-tooltip
                width="200"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="标题"
                prop="title"
                width="130"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <p
                        style="display: flex; align-items: center"
                        @click="onDetails(scope.row)"
                    >
                        <img
                            v-if="scope.row.reminderStatus == 1"
                            style="margin-right: 4px"
                            src="@/assets/cui.png"
                            alt=""
                        />
                        <span style="display: block; width: 28px" v-else></span>
                        <el-button type="text" style="color: #2370eb">{{
                            scope.row.title
                        }}</el-button>
                    </p>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="优先级"
                prop="priority"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.priority == 1
                            ? '一般'
                            : scope.row.priority == 2
                            ? '高'
                            : scope.row.priority == 3
                            ? '低'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="受理人"
                prop="acceptAdminName"
                show-overflow-tooltip
                width="60"
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="工单状态" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.status == 1
                            ? '待处理'
                            : scope.row.status == 2
                            ? '处理中'
                            : scope.row.status == 3
                            ? '已处理'
                            : scope.row.status == 4
                            ? '已关闭'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="工单类型" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.category == 1
                            ? '管理员问题'
                            : scope.row.category == 2
                            ? '成员问题'
                            : scope.row.category == 3
                            ? '客户端问题'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="创建人"
                width="60"
                prop="adminName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="创建时间" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.createTime({
                            createTime: scope.row.createTime,
                        })
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="更新时间" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        $tableDataHandle.createTime({
                            createTime: scope.row.updateTime,
                        })
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="催单次数"
                prop="reminderNumber"
                width="70"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="120">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        size="samll"
                        style="color: #2370eb"
                        @click="onTransfer(scope.row)"
                        v-if="
                            btnP.transfer &&
                            (scope.row.status == 1 || scope.row.status == 2)
                        "
                        >转单</el-button
                    >
                    <el-button
                        type="text"
                        size="samll"
                        style="color: #2370eb"
                        v-if="
                            btnP.urge &&
                            (scope.row.status == 1 || scope.row.status == 2)
                        "
                        @click="onUrge(scope.row)"
                        >催单</el-button
                    >
                    <el-button
                        type="text"
                        size="samll"
                        style="color: #2370eb"
                        v-if="btnP.urge && scope.row.status == 3"
                        @click="onFinish(scope.row)"
                        >确认</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 新建 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleAdd"
            size="480px"
        >
            <template slot="title">
                <div style="display: flex; align-items: center">新增工单</div>
            </template>
            <Add @close="handleAdd" ref="add" />
        </el-drawer>
        <!-- 转单 -->
        <el-dialog
            :visible.sync="drawerTransfer"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择联系人
                </div>
            </template>
            <Transfer @close="handleClose" ref="transfer" />
        </el-dialog>
        <!-- 确认 -->
        <el-dialog
            :visible.sync="drawerFinish"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    确认完成
                </div>
            </template>
            <Finish @close="handleClose" ref="finish" />
        </el-dialog>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="drawerDetails"
            :direction="direction"
            :before-close="handleDetails"
            size="880px"
        >
            <template slot="title">
                <div style="display: flex; align-items: center">
                    {{ drawerTitle }}
                </div>
            </template>
            <Details @close="handleDetails" ref="details" />
        </el-drawer>
    </div>
</template>
<script>
import { workorderList, workorderUpdate } from '@/api/cs/workOrder.js';
import Add from '../addWorkOrder/add.vue';
import Transfer from '../transfer/transfer.vue';
import Details from '../details/details.vue';
import Finish from '../finish/finish.vue';
import { departmentList } from '@/api/admin/framework/framework.js';
import { roleList } from '@/api/customer/customer.js';
import Department from '../../../../components/Department';
export default {
    components: {
        Add,
        Transfer,
        Details,
        Finish,
        Department,
    },
    data() {
        return {
            fold: true,
            searchVal: {},
            dateChoice: '',
            status: '待处理',
            departmentList: [],
            userName: [],
            btnP: {},
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            drawerAdd: false,
            direction: 'rtl',
            drawerTransfer: false,
            drawerUrge: false,
            drawerTitle: '',
            drawerDetails: false,
            drawerFinish: false,
        };
    },

    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDepmList();
            }
            if (!btn.depSearch && btn.searchUser) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        // 获取部门
        getDepmList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        searchData(data) {
            this.pagesize = 20;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        // 搜索获取列表
        getData() {
            // console.log(this.searchVal);
            let data = {
                param: {
                    name: this.searchVal.name,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (this.status == '待处理') {
                data.param.status = 1;
            } else if (this.status == '处理中') {
                data.param.status = 2;
            } else if (this.status == '已处理') {
                data.param.status = 3;
            } else if (this.status == '已关闭') {
                data.param.status = 4;
            }

            if (!this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                if (this.searchVal.adminId) {
                    data.param.adminId = this.searchVal.adminId;
                }
            }
            if (this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.departmentId = this.searchVal.departmentId;
            }
            if (this.btnP.depSearch && this.btnP.searchUser) {
                data.param.departmentId = this.searchVal.departmentId;
                data.param.adminId = this.searchVal.adminId;
            }
            if (!this.btnP.depSearch && !this.btnP.searchUser) {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (this.dateChoice != '') {
                let date = new Date();
                this.searchVal.searchDate = [];
                data.param.endCreateDate = this.$searchTime.getNextDate(
                    date,
                    0
                );
                if (this.dateChoice == '3天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        3
                    );
                } else if (this.dateChoice == '10天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        10
                    );
                } else if (this.dateChoice == '30天内') {
                    data.param.startCreateDate = this.$searchTime.getNextDate(
                        date,
                        30
                    );
                }
            }
            if (
                this.searchVal.searchDate &&
                this.searchVal.searchDate.length > 0
            ) {
                data.param.startCreateDate = this.searchVal.searchDate[0];
                data.param.endCreateDate = this.searchVal.searchDate[1];
            }
            // console.log(data);
            workorderList(data).then((res) => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                data = {};
            });
        },

        // 单选时间
        clickitem(e) {
            e === this.dateChoice
                ? (this.dateChoice = '')
                : (this.dateChoice = e);
            this.getData();
        },
        clickStatus(e) {
            this.status = e;
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 新建
        onAdd() {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.add.getUserList();
            });
        },
        handleAdd() {
            this.drawerAdd = false;
            this.getData();
        },
        // 转单
        onTransfer(row) {
            this.drawerTransfer = true;
            setTimeout(() => {
                this.$refs.transfer.getUserList(row);
            });
        },
        // 催单
        onUrge(row) {
            let data = {
                param: {
                    id: row.id,
                    reminderStatus: 1,
                },
            };
            workorderUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.getData();
                }
            });
        },
        handleClose() {
            this.drawerUrge = false;
            this.drawerTransfer = false;
            this.drawerFinish = false;
            this.getData();
        },
        // 确认完成
        onFinish(row) {
            this.drawerFinish = true;
            setTimeout(() => {
                this.$refs.finish.getData(row);
            });
        },
        // 详情
        onDetails(row) {
            let btn = JSON.parse(sessionStorage.getItem('isButArr'));
            let type = false;
            var btnChild = [];
            for (let i in btn) {
                if (btn[i].route == 'upData') {
                    type = true;
                    break;
                }
            }
            if (type) {
                this.drawerTitle = '编辑工单';
                for (let i in btn) {
                    if (btn[i].route == 'upData') {
                        btnChild = btn[i].children;
                    }
                }
            } else {
                this.drawerTitle = '工单详情';
            }
            this.drawerDetails = true;
            let btnP = {};
            for (let i in btnChild) {
                btnP['' + btnChild[i].route] = true;
            }
            // console.log(btnP);
            setTimeout(() => {
                this.$refs.details.getData(row, btnP, type);
            }, 0);
            this.$formDetails.commit('detailsData', JSON.stringify(row));
            this.$choiceLabel.commit('getIsDetails', type);
            this.$choiceLabel.commit('getType', '2');
        },
        handleDetails() {
            this.drawerDetails = false;
            this.getData();
        },
        // 重置
        searchEmpty() {
            this.searchVal = {};
            this.status = '待处理';
            this.dateChoice = '';
            this.getData();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.database {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 500;
            color: #333333;
            margin: 0 16px;
        }
        .search-one {
            padding: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
        .cancel:hover {
            background: #fff;
            color: #666;
            border-color: #e5e5e5;
        }
    }
    .table {
        flex: 1;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog_del {
        .el-dialog__footer {
            padding: 0;
        }
    }
}
.dialog_add_customer {
    .el-dialog__footer {
        padding: 0;
    }
    .dialog_add_customer_body {
        width: 65%;
        margin: 60px auto 150px;
    }
}
.search-two {
    margin-bottom: 10px;
}
/deep/ th:nth-child(3) .cell {
    padding-left: 38px;
}
</style>
