<template>
    <div class="choice_content">
        <div class="choice_box">
            <div class="table_box">
                <div class="search">
                    <span>快速查询</span>
                    <el-input
                        size="small"
                        style="width:240px;margin-left:16px"
                        placeholder="请输入联系人"
                        v-model="searchVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="onSearch()"
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        style="background:#2370EB;border-color:#2370EB"
                    ></el-button>
                    <el-button
                        @click="onAddContacts()"
                        type="primary"
                        size="mini"
                        style="background:#fff;border-color:#2370EB;color:#2370EB"
                        >添加联系人</el-button
                    >
                </div>
                <el-table
                    style="flex:1"
                    ref="table"
                    @selection-change="handleSelectionChange"
                    highlight-current-row
                    :data="tableData"
                    height="100%"
                    :header-cell-style="{
                        background: '#FAFAFA',
                        color: '#333333',
                        'font-size': '14px',
                        'font-weight': '500',
                    }"
                >
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="contactName" label="联系人">
                        <template slot-scope="scope">{{
                            scope.row.contactName
                                ? scope.row.contactName
                                : '- -'
                        }}</template>
                    </el-table-column>
                    <el-table-column label="客户名称">
                        <template slot-scope="scope">
                            <span v-if="scope.row.customerBO">{{
                                scope.row.customerBO.companyName
                                    ? scope.row.customerBO.companyName
                                    : '- -'
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="contractB" label="跟进人">
                        <template slot-scope="scope">
                            <span
                                v-if="
                                    scope.row.customerBO &&
                                        scope.row.customerBO.followAdminName
                                "
                                >{{
                                    scope.row.customerBO.followAdminName
                                        ? scope.row.customerBO.followAdminName
                                        : '- -'
                                }}</span
                            >
                            <span v-else>{{ scope.row.adminName }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="selected">
                <p class="title">已选择</p>
                <div class="selected_list">
                    <p v-for="item in multipleSelection">
                        <span>{{
                            item.contactName ? item.contactName : '- -'
                        }}</span>
                        <el-button
                            @click.prevent="removeContact(item)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </p>
                </div>
            </div>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
        <!-- 添加联系人 -->
        <el-drawer
            :visible.sync="drawerAdd"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
            append-to-body
        >
            <template slot="title">
                <div style="display:flex; align-items: center;">
                    新增联系人
                </div>
            </template>
            <AddContacts @close="handleClose" ref="contacts" />
        </el-drawer>
    </div>
</template>
<script>
import AddContacts from './addContacts.vue';
import { contactList } from '@/api/cs/workOrder.js';
export default {
    components: {
        AddContacts,
    },
    data() {
        return {
            tableData: [],
            searchVal: '',
            multipleSelection: [],
            drawerAdd: false,
            direction: 'rtl',
        };
    },
    methods: {
        getData() {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            contactList(data).then((res) => {
                // console.log(res);
                this.tableData = res.data.list;
            });
        },
        onSearch() {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    contactName: this.searchVal,
                },
                pageNum: 0,
                pageSize: 0,
            };
            contactList(data).then((res) => {
                this.tableData = res.data.list;
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        removeContact(item) {
            // console.log(item);
            this.tableData.forEach((i) => {
                if (i == item) {
                    this.$refs.table.toggleRowSelection(i, false);
                }
            });
        },
        onSubmit() {
            this.$emit('choiceSubmit', this.multipleSelection);
        },
        onClose() {
            this.$emit('close');
            this.tableData = [];
        },
        onAddContacts() {
            this.drawerAdd = true;
            setTimeout(() => {
                this.$refs.contacts.getCustomerList();
            });
        },
        handleClose() {
            this.drawerAdd = false;
            this.getData();
            this.$choiceLabel.commit('emptyData');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.choice_content {
    display: flex;
    flex-direction: column;
    height: 480px;
    .choice_box {
        display: flex;
        flex: 1;
        .table_box {
            height: 480px;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 30px 0 35px;
            .search {
                padding: 14px 0;
            }
        }
        .selected {
            height: 480px;
            border-left: 1px solid #e5e5e5;
            width: 355px;
            display: flex;
            flex-direction: column;
            .title {
                padding: 18px 0 14px 14px;
            }
            .selected_list {
                flex: 1;
                overflow: auto;
                padding: 0 30px 0 10px;
                p {
                    line-height: 32px;
                    background: #f9f9f9;
                    margin-bottom: 10px;
                    padding: 0 10px;
                    border-radius: 2px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }

    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;

        text-align: right;

        button {
            width: 90px;
        }
    }
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 17px;
}
</style>
