var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"choice_content"},[_c('div',{staticClass:"choice_box"},[_c('div',{staticClass:"table_box"},[_c('div',{staticClass:"search"},[_c('span',[_vm._v("快速查询")]),_c('el-input',{staticStyle:{"width":"240px","margin-left":"16px"},attrs:{"size":"small","placeholder":"请输入联系人","clearable":""},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}),_c('el-button',{staticStyle:{"background":"#2370EB","border-color":"#2370EB"},attrs:{"type":"primary","size":"small","icon":"el-icon-search"},on:{"click":function($event){return _vm.onSearch()}}}),_c('el-button',{staticStyle:{"background":"#fff","border-color":"#2370EB","color":"#2370EB"},attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onAddContacts()}}},[_vm._v("添加联系人")])],1),_c('el-table',{ref:"table",staticStyle:{"flex":"1"},attrs:{"highlight-current-row":"","data":_vm.tableData,"height":"100%","header-cell-style":{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"prop":"contactName","label":"联系人"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.contactName ? scope.row.contactName : '- -'))]}}])}),_c('el-table-column',{attrs:{"label":"客户名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.customerBO)?_c('span',[_vm._v(_vm._s(scope.row.customerBO.companyName ? scope.row.customerBO.companyName : '- -'))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"contractB","label":"跟进人"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                                scope.row.customerBO &&
                                    scope.row.customerBO.followAdminName
                            )?_c('span',[_vm._v(_vm._s(scope.row.customerBO.followAdminName ? scope.row.customerBO.followAdminName : '- -'))]):_c('span',[_vm._v(_vm._s(scope.row.adminName))])]}}])})],1)],1),_c('div',{staticClass:"selected"},[_c('p',{staticClass:"title"},[_vm._v("已选择")]),_c('div',{staticClass:"selected_list"},_vm._l((_vm.multipleSelection),function(item){return _c('p',[_c('span',[_vm._v(_vm._s(item.contactName ? item.contactName : '- -'))]),_c('el-button',{staticClass:"el-icon-minus",attrs:{"type":"primary","circle":"","size":"mini"},on:{"click":function($event){$event.preventDefault();return _vm.removeContact(item)}}})],1)}),0)])]),_c('div',{staticClass:"bottom-but"},[_c('el-button',{staticStyle:{"background":"none","border-color":"#D5D6DF","color":"#666666"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onClose}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"background":"#2370EB","border-color":"#2370EB","color":"#fff","margin-right":"32px"},attrs:{"round":"","type":"primary","size":"mini"},on:{"click":_vm.onSubmit}},[_vm._v("确定")])],1),_c('el-drawer',{attrs:{"visible":_vm.drawerAdd,"direction":_vm.direction,"before-close":_vm.handleClose,"size":"480px","append-to-body":""},on:{"update:visible":function($event){_vm.drawerAdd=$event}}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" 新增联系人 ")])]),_c('AddContacts',{ref:"contacts",on:{"close":_vm.handleClose}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }