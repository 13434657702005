<template>
  <div class="finish">
    <p class="content">
      该问题已处理，请确认问题是否解决，<br />若问题未解决，将再次发送给工作人员处理
    </p>
    <div class="bottom-but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;"
        @click="onSubmit(5)"
        >问题未解决</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:32px"
        size="mini"
        @click="onSubmit(4)"
        >问题已解决</el-button
      >
    </div>
  </div>
</template>
<script>
import { workorderUpdate } from "@/api/cs/workOrder.js";
export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    getData(data) {
      this.data = data;
    },
    onSubmit(i) {
      let data = {
        param: {
          id: this.data.id,
          status: i,
        },
      };
      workorderUpdate(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("操作成功");
          this.$emit("close");
        }
      });
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.content {
  font-size: 15px;
  color: #333;
  text-align: center;
  padding: 100px 0;
  line-height: 20px;
}
.bottom-but {
  width: 100%;
  line-height: 48px;
  background: #fff;
  border-top: 1px solid #dee0e3;
  margin-top: 16px;
  text-align: right;
  button {
    width: 90px;
  }
}
</style>
