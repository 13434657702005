<template>
    <div class="add">
        <el-form
            ref="form"
            :model="form"
            style="padding-left:43px;flex:1; margin-top: 20px;"
            label-width="100px"
            label-position="left"
        >
            <el-form-item label="工单分类" required>
                <el-select
                    v-model="form.category"
                    size="small"
                    style="width:270px;"
                    placeholder="请选择工单分类"
                    clearable
                    @change="problemType()"
                >
                    <el-option label="管理员问题" value="1"></el-option>
                    <el-option label="成员问题" value="2"></el-option>
                    <el-option label="客户端问题" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="工单标题" required>
                <el-input
                    v-model="form.title"
                    placeholder="请输入工单标题"
                    size="small"
                    style="width:270px;"
                ></el-input>
            </el-form-item>
            <el-form-item label="问题类型" required>
                <el-select
                    v-model="form.problemType"
                    size="small"
                    filterable
                    style="width:270px;"
                    clearable
                    placeholder="请选择或输入关键字搜索"
                >
                    <el-option
                        v-for="item in problem"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="描述">
                <el-input
                    v-model="form.workOrderDescribe"
                    placeholder="请输描述"
                    size="small"
                    style="width:270px;"
                    type="textarea"
                    :rows="3"
                ></el-input>
            </el-form-item>
            <el-form-item label="附件">
                <el-upload
                    :on-progress="progress"
                    :action="action"
                    class="avatar-uploader"
                    list-type="picture-card"
                    :on-success="onAttachment"
                    :before-upload="beforeAvatarUploads"
                    multiple
                    :file-list="fileList"
                    :limit="5"
                    ref="attachment"
                    accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                >
                    <el-button
                        slot="default"
                        size="small"
                        type="primary"
                        style="background:#fff;padding:6px 24px;color:#2370EB"
                        >添加</el-button
                    >
                    <div
                        slot="file"
                        slot-scope="{ file }"
                        style="text-align:center"
                    >
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.attachmentUrl"
                            v-if="
                                file.attachmentUrl &&
                                    (file.attachmentUrl.indexOf('.jpg') != -1 ||
                                        file.attachmentUrl.indexOf('.png') !=
                                            -1 ||
                                        file.attachmentUrl.indexOf('.gif') !=
                                            -1)
                            "
                        />
                        <span
                            v-if="
                                file.attachmentUrl &&
                                    (file.attachmentUrl.indexOf('.pdf') != -1 ||
                                        file.attachmentUrl.indexOf('.PDF') !=
                                            -1)
                            "
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            v-if="
                                file.attachmentUrl &&
                                    file.attachmentUrl.indexOf('.doc') != -1
                            "
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                        <span
                            v-if="
                                file.attachmentUrl &&
                                    (file.attachmentUrl.indexOf('.MP4') != -1 ||
                                        file.attachmentUrl.indexOf('.mp4') !=
                                            -1)
                            "
                        >
                            <img
                                style="width:100px"
                                src="@/assets/video.png"
                                alt=""
                            />
                        </span>
                        <span class="el-upload-list__item-actions">
                            <span
                                class="el-upload-list__item-preview"
                                @click="handlePictureCardPreview(file)"
                                v-if="
                                    file.attachmentUrl &&
                                        (file.attachmentUrl.indexOf('.jpg') !=
                                            -1 ||
                                            file.attachmentUrl.indexOf(
                                                '.png'
                                            ) != -1 ||
                                            file.attachmentUrl.indexOf(
                                                '.gif'
                                            ) != -1)
                                "
                            >
                                <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleDownload(file)"
                            >
                                <i class="el-icon-download"></i>
                            </span>
                            <span
                                v-if="!disabled"
                                class="el-upload-list__item-delete"
                                @click="handleRemoves(file)"
                            >
                                <i class="el-icon-delete"></i>
                            </span>
                        </span>
                    </div>
                </el-upload>
            </el-form-item>
            <el-form-item label="受理人" required>
                <el-select
                    style="width:270px"
                    v-model="form.acceptAdminId"
                    size="small"
                    clearable
                    placeholder="请选择"
                >
                    <el-option
                        v-for="item in acceptList"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="优先级">
                <el-select
                    v-model="form.priority"
                    size="small"
                    style="width:270px;"
                    placeholder="请选择优先级"
                >
                    <el-option label="一般" value="1"></el-option>
                    <el-option label="高" value="2"></el-option>
                    <el-option label="低" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关联联系人" required>
                <el-button
                    @click="onChoiceContacts()"
                    type="primary"
                    size="mini"
                    style="background:#fff;border-color:#2370EB;color:#2370EB"
                    >添加联系人</el-button
                >
                <div class="selected_list">
                    <p v-for="item in contactsList">
                        <span>{{ item.contactName }}</span>
                        <el-button
                            @click.prevent="removeContact(item)"
                            class="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </p>
                </div>
            </el-form-item>
        </el-form>
        <div class="but">
            <el-button
                :loading="loading"
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                :loading="loading"
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
        <!-- 添加关联联系人 -->
        <el-dialog
            :visible.sync="drawerChoice"
            width="880px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    选择联系人
                </div>
            </template>
            <ChoiceContacts
                @close="handleClose"
                ref="choice"
                @choiceSubmit="choiceSubmit"
            />
        </el-dialog>
    </div>
</template>
<script>
import ChoiceContacts from './choiceContacts.vue';
import { roleList } from '@/api/customer/customer';
import { workorderAdd } from '@/api/cs/workOrder.js';

export default {
    components: {
        ChoiceContacts,
    },
    data() {
        return {
            form: {
                priority: '1',
            },
            contactsLis: [],
            fileList: [],
            drawerChoice: false,
            problem: [],
            acceptList: [],
            contactsList: [],
            action: this.$url.url() + '/file/local/upload/contract',
            disabled: false,
            fileArr: [],
            loading: false,
        };
    },
    methods: {
        onSubmit() {
            if (!this.form.category) {
                return this.$message.error('请选择工单分类');
            }
            if (!this.form.title) {
                return this.$message.error('请输入工单标题');
            }
            if (!this.form.problemType) {
                return this.$message.error('请选择问题类型');
            }
            if (!this.form.acceptAdminId) {
                return this.$message.error('请选择受理人');
            }
            if (this.contactsList.length == 0) {
                return this.$message.error('请选择关联联系人');
            }
            let data = {
                param: {
                    workOrderDO: this.form,
                    workOrderAttachmentDOList: this.fileList,
                    customerContactDOList: this.contactsList,
                },
            };
            workorderAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
            this.form = {};
            this.fileList = [];
            this.contactsList = [];
        },
        // 受理人
        getUserList(row) {
            this.data = row;
            let data = {
                param: {
                    roleTypeList: [16],
                },
            };
            roleList(data).then((res) => {
                // console.log(res);
                this.acceptList = res.data;
            });
        },
        onChoiceContacts() {
            this.drawerChoice = true;
            setTimeout(() => {
                this.$refs.choice.getData();
            });
        },
        choiceSubmit(row) {
            this.contactsList = row;
            this.drawerChoice = false;
        },
        removeContact(item) {
            for (let i in this.contactsList) {
                if (this.contactsList[i].id == item.id) {
                    this.contactsList.splice(i, 1);
                }
            }
        },
        handleClose() {
            this.drawerChoice = false;
            this.$refs.choice.tableData = [];
        },
        problemType() {
            if (this.form.category == 1) {
                this.problem = this.$csForm.adminProblem();
            } else if (this.form.category == 2) {
                this.problem = this.$csForm.userProblem();
            } else if (this.form.category == 3) {
                this.problem = this.$csForm.clientProblem();
            }
        },
        progress() {
            this.loading = true;
        },
        // 上传附件
        onAttachment(response) {
            this.fileArr.push({ attachmentUrl: response.data });
            setTimeout(() => {
                this.fileList = this.fileArr;
                this.loading = false;
            }, 2000);
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isLt2M;
        }, // 附件删除
        handleRemoves(file) {
            for (let i in this.fileList) {
                if (this.fileList[i].url == file.url) {
                    this.fileArr.splice(i, 1);
                }
            }
            this.fileList = this.fileArr;
        },
        // 预览图片
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.attachmentUrl;
            this.dialogVisible = true;
        },
        // 下载
        handleDownload(file) {
            // console.log(file.attachmentUrl);
            if (
                file.attachmentUrl.indexOf('.doc') != -1 ||
                file.attachmentUrl.indexOf('.docx') != -1
            ) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                        file.attachmentUrl
                );
            } else {
                window.open(file.attachmentUrl, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.selected_list {
    flex: 1;
    overflow: auto;
    padding: 0 30px 0 0;
    p {
        line-height: 32px;
        background: #f9f9f9;
        margin-bottom: 10px;
        padding: 0 10px;
        border-radius: 2px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .el-button--mini.is-circle {
        padding: 2px;
        background: #fff;
        color: #2370eb;
        font-weight: 600;
        width: 20px;
        height: 20px;
        border: 2px solid #2370eb;
        margin-left: 17px;
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
/deep/ .el-upload-list__item:hover {
    background-color: #fff;
}
/deep/ .el-upload--picture-card {
    background-color: transparent;
    width: auto;
    height: auto;
    border: none;
    line-height: 1;
    display: block;
    text-align: left;
}
/deep/ .el-upload-list__item.is-success {
    width: 100px !important;
    height: 100px !important;
}
</style>
